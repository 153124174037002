import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineCompetentDoctorPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Medico Competente'

  const description =
    '<b>ING GROUP</b> dispone di un’equipe di medici competenti distribuiti su tutto il territorio nazionale, unitamente a 50 centri medici, per la gestione della sorveglianza sanitaria in tutte le sue forme ed applicazioni.<br> Forniamo assistenza sanitaria ad aziende grandi, medie e piccole assicurando la disponibilità di medici competenti sia per la funzione di medico coordinatore che coordinati.'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
